import { useNavigate, useParams, useLocation } from 'react-router-dom';
import q from 'query-string';
import { useMemo, useCallback } from 'react';

const useParamsCustom = () => {
  const location = useLocation();
  const routerParams = useParams();
  const navigate = useNavigate();
  const { search, pathname } = location;
  const params = useMemo(() => {
    return q.parse(search, { arrayFormat: 'bracket' });
  }, [search]);

  const setParams = useCallback((newParams, toDelete = []) => {
    const combinedParams = {
      ...q.parse(search, { arrayFormat: 'bracket' }),
      ...newParams
    };
    toDelete.filter(Boolean).forEach(key => (delete combinedParams[key]));
    navigate('?' + q.stringify(combinedParams, { arrayFormat: 'bracket' }));
  }, [navigate, search]);

  return { params, setParams, navigate, search, pathname, routerParams };
};

export default useParamsCustom;
