import { useEffect, useState, useRef, useCallback } from 'react';
import q from 'query-string';

const makeUseResource = () => {
  let api;
  let queryParams = {};

  const configure = ({ apiInstance, qParams }) => {
    api = apiInstance;
    queryParams = qParams;
  };

  const useResource = (url, defaultValue, query, shouldLoad=true) => {
    const [resource, setResource] = useState(defaultValue);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const search = q.stringify(query, queryParams);
    const mounted = useRef(true);

    const nextPage = useCallback(async () => {
      setLoading(true);
      const params = q.parse(search, queryParams);
      params.page = (resource.page || 1) + 1;
      const { data } = await api.get(url, { params: params });

      if (!mounted.current) return;
      setResource(r => ({ ...data, result: [...r.result, ...data.result] }));
      setLoading(false);
    }, [search, url, resource]);

    const load = useCallback(async (url, search) => {
      if(!shouldLoad) return;
      try {
        setError();
        const { data } = await api.get(url, { params: q.parse(search, queryParams) });
        return data;
      } catch (error) {
        setError(error);
        return null;
      }
    }, [shouldLoad]);

    const silentReload = useCallback(async () => {
      if(!shouldLoad) return;
      const data = await load(url, search);

      if (!mounted.current) return;
      if(data) setResource(data || {});
    }, [load, search, url, shouldLoad]);

    const loadResource = useCallback(async () => {
      if(!shouldLoad) return;
      setLoading(true);
      const data = await load(url, search);

      if (!mounted.current) return;
      if(data) setResource(data || {});
      setLoading(false);
    }, [load, search, url, shouldLoad]);

    useEffect(() => {
      loadResource();
    }, [loadResource]);

    useEffect(() => {
      mounted.current = true;
      return () => {
        mounted.current = false;
      };
    }, []);

    const reload = useCallback(() => loadResource(), [loadResource]);

    return { resource, loading, reload, setResource, nextPage, silentReload, error };
  };

  return Object.assign(useResource, {
    configure
  });
};

const hook = makeUseResource();

export default hook;
