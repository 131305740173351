import { useCallback } from 'react';
import useWebSocket from 'react-use-websocket';

const makeWs = () => {
  let url;
  let q = {};
  const subs = {};
  const initializeWs = (path, query) => {
    url = path;
    q = query;
  };

  const STATIC_OPTIONS = {
    onMessage: (m) => {
      const parsed = JSON.parse(m.data);
      if (parsed.type === 'ping' || !parsed.message) return;
      const { channel } = JSON.parse(parsed.identifier);
      if (subs[channel]) Object.values(subs[channel]).forEach(callback => callback(parsed.message));
    },
    shouldReconnect: (closeEvent) => true,
    queryParams: q
  };

  const useWs = () => {
    const { sendMessage, readyState } = useWebSocket(url, STATIC_OPTIONS);

    const subscribe = useCallback((identifier, callback) => {
      if (readyState === 0) return;

      if (!subs[identifier.channel]) {
        sendMessage(JSON.stringify({
          command: 'subscribe',
          identifier: JSON.stringify(identifier)
        }));
      }
      subs[identifier.channel] = subs[identifier.channel] || {};
      subs[identifier.channel][callback.name] = callback.function;
    }, [readyState]);

    const unsubscribe = useCallback((identifier) => {
      delete subs[identifier.channel][identifier.name];
    }, []);

    return {
      subscribe,
      unsubscribe,
      readyState
    };
  };
  return Object.assign(useWs, {
    initializeWs
  });
};

const hook = makeWs();

export default hook;
